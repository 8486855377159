import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"
import {
  ArticleAuthorInfo,
  ArticleDate,
} from "../../styledComponents/article"
import LayoutNoStripe from "../../components/layoutNoStripe"

import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo
} from "../../styledComponents/videoTestimonial"


const QuantumTouchEnergyBoostWorkshop = ({ data, location }) => {
  
  const [isAutopPlay, setAutoPlay] = useState(false);
  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">Quantum-Touch Energy Boost Workshop </h1>
          <ArticleAuthorInfo className="author"><span>Taught by <Link to="/about">Karina Grant</Link></span> </ArticleAuthorInfo>
          <ArticleDate offScreen dateTime=''>Last updated on November 12th 2024</ArticleDate>
        </StyledVideoTestimonialHeader>


        <StyledVideoTestimonialVideo>

          <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="A 2-hour Quantum-Touch Energy Boost Workshop taught by Karina Grant" width="100%" height="100%" src={'https://karinagrant.s3.eu-west-1.amazonaws.com/quantum-touch/EnergyBoostWorkshopNovember.mp4'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" controlsList="nodownload" allowfullscreen=""></iframe></div></figure>
        </StyledVideoTestimonialVideo>
                
      </StyledVideoTestimonialArticle>
    </LayoutNoStripe>
  )
}

export default QuantumTouchEnergyBoostWorkshop

